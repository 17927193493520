[data-theme="dark"] {
  --c-background: 257, 9%, 15%; /* HSL / #242229 */
  --cs-red: 350, 60%, 50%; /* HSL / #CC334D */
  --cs-alt-red: 0, 80%, 80%; /* HSL / #F5A3A3 */
  --cs-yellow: 40, 80%, 60%; /* HSL / #EBB347 */
  --cs-alt-yellow: 40, 70%, 70%; /* HSL / #E8C47D */
  --cs-green: 100, 40%, 45%; /* HSL / #63A145 */
  --cs-alt-green: 80, 60%, 65%; /* HSL / #B8DB70 */
  --cs-alt-purple-1: 250, 60%, 40%; /* HSL / #3D29A3 */
  --cs-purple: 250, 60%, 55%; /* HSL / #5E47D1 */
  --cs-alt-purple-0: 250, 80%, 80%; /* HSL / #B1A3F5 */
  --cg-neutral-7: 252, 10%, 10%; /* HSL / #18171C */
  --cg-neutral-6: 255, 11%, 15%; /* HSL / #24222A */
  --cg-neutral-5: 247, 10%, 17%; /* HSL / #282730 */
  --cg-neutral-4: 252, 10%, 20%; /* HSL / #302E38 */
  --cg-neutral-3: 254, 10%, 25%; /* HSL / #3C3946 */
  --cg-neutral-2: 249, 10%, 60%; /* HSL / #928FA3 */
  --cg-neutral-1: 252, 10%, 80%; /* HSL / #C9C7D1 */
  --cg-neutral-0: 0, 0%, 100%; /* HSL / #FFFFFF */
  --shadow-small: 0 1px 2px hsla(var(--cg-neutral-7), 15%),
    0 2px 4px hsla(var(--cg-neutral-7), 15%);
  --shadow-large: 0 4px 8px hsla(var(--cg-neutral-7), 15%),
    0 8px 16px hsla(var(--cg-neutral-7), 15%);
}
