@import "npm:@quasar-ui/fonts/fonts.css";
@import "npm:@quasar-ui/styles/utils/base.css";
@import "npm:@quasar-ui/styles/themes/dark.css";

body {
  overflow: hidden;
}

main {
  display: flex;
  background: radial-gradient(
    hsla(var(--cg-neutral-6), 75%),
    hsla(var(--cg-neutral-7), 25%)
  );
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  max-width: 450px;
}

.assistive-hide {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.skydome-logo {
  width: 100%;
  max-width: 256px;
  margin: 0 48px 48px;
  opacity: 0.85;
}

.video-background {
  position: fixed;
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form-field {
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;
}
.form-input-container {
  display: flex;
  gap: 16px;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  background: hsla(var(--cg-neutral-7), 95%);
  backdrop-filter: blur(4px);
}
.form-input {
  display: block;
  width: 100%;
  height: 38px;
  color: hsl(var(--cg-neutral-0));
  background: hsla(var(--cg-neutral-4), 50%);
  border: 2px solid hsla(var(--cg-neutral-3), 95%);
  border-radius: 8px;
  margin-bottom: 0;
  padding: 24px 8px;
  font: var(--f-functional);
}
.form-input:focus-visible {
  outline-offset: 0px;
  color: hsl(var(--cg-neutral-7));
  background-color: hsl(var(--cg-neutral-0));
  border-color: hsl(var(--cg-neutral-7));
  outline: 3px solid hsl(var(--cs-alt-yellow));
}
.form-button-primary {
  color: hsl(var(--cg-neutral-7));
  background-color: hsl(var(--cg-neutral-1));
  border-radius: 8px;
  padding: 14px;
  font: var(--f-functional);
  border: 0;
  text-decoration: none;
  cursor: pointer;
}
.form-button-primary:focus-visible {
  outline-offset: -4px;
  color: hsl(var(--cg-neutral-7));
  background-color: hsl(var(--cs-alt-yellow));
  outline: 2px solid hsl(var(--cg-neutral-7));
}
.from-message {
  display: block;
  padding: 16px;
  margin: 0 0 16px;
  border-radius: 8px;
  font: var(--f-functional);
  text-align: center;
  backdrop-filter: blur(4px);
}
.form-done {
  display: none;
  background: hsla(var(--cs-alt-green), 75%);
  color: hsl(var(--cg-neutral-7));
}
.form-fail {
  display: none;
  background: hsla(var(--cs-alt-red), 75%);
  color: hsl(var(--cg-neutral-7));
}

.powered-by {
  width: 200px;
  padding: 8px;
  border-radius: 10px;
}
.powered-by-logo {
  width: 100%;
  opacity: 0.5;
}
.powered-by:focus-visible
.powered-by-logo {
  filter: invert(1);
  opacity: 1;
}