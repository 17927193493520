@font-face {
  font-family: IBM Plex Sans;
  src: local(IBM Plex Sans Var), url("ibm-plex-sans-var.dd481e65.woff2") format("woff2-variations"), url("https://23852212.fs1.hubspotusercontent-na1.net/hubfs/23852212/quasar-ui/fonts/ibm-plex-sans-var.woff2") format("woff2-variations");
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: IBM Plex Mono;
  src: local(IBM Plex Mono), url("ibm-plex-mono-regular.b5a5bf14.woff2") format("woff2"), url("https://23852212.fs1.hubspotusercontent-na1.net/hubfs/23852212/quasar-ui/fonts/ibm-plex-mono-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  src: local(Poppins), url("poppins-semibold.414c1233.woff2") format("woff2"), url("https://23852212.fs1.hubspotusercontent-na1.net/hubfs/23852212/quasar-ui/fonts/poppins-semibold.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --fs-smallest: .938em;
  --fs-base: 1em;
  --fs-medium: 1.2em;
  --fs-large: 1.44em;
  --fs-xlarge: 1.728em;
  --fs-xxlarge: 2.074em;
  --fs-xxxlarge: 2.488em;
  --fs-largest: 2.986em;
  --ff-heading: "Poppins";
  --ff-article: "IBM Plex Sans";
  --ff-paragraph: "IBM Plex Sans";
  --ff-functional: "IBM Plex Sans";
  --ff-code: "IBM Plex Mono";
  --f-heading-1: 500 var(--fs-largest) / var(--fs-largest) var(--ff-heading);
  --f-heading-2: 500 var(--fs-xxxlarge) / var(--fs-largest) var(--ff-heading);
  --f-heading-3: 500 var(--fs-xxlarge) / var(--fs-xxxlarge) var(--ff-heading);
  --f-heading-4: 500 var(--fs-xlarge) / var(--fs-xxlarge) var(--ff-heading);
  --f-heading-5: 500 var(--fs-large) / var(--fs-xlarge) var(--ff-heading);
  --f-heading-6: 500 var(--fs-medium) / var(--fs-large) var(--ff-heading);
  --f-article: 400 var(--fs-base) / var(--fs-xlarge) var(--ff-article);
  --f-paragraph: 400 var(--fs-smallest) / var(--fs-medium) var(--ff-paragraph);
  --f-functional: 400 var(--fs-base) / var(--fs-medium) var(--ff-functional);
  --f-alt-functional: 400 var(--fs-smallest) / var(--fs-base) var(--ff-functional);
  --f-code: 400 var(--fs-smallest) / var(--fs-large) var(--ff-code);
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: hsl(var(--cg-neutral-2)) transparent;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  min-height: 100%;
  padding: 0 !important;
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-corner {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: hsl(var(--cg-neutral-2));
  background-clip: padding-box;
  border: 4px solid #0000;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(var(--cg-neutral-1));
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:active {
  background: hsl(var(--cg-neutral-1));
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:active {
  border: 3px solid #0000;
}

::-webkit-scrollbar-track:active {
  background: hsla(var(--cg-neutral-1), 15%);
}

[data-theme="dark"] ::selection {
  color: hsl(var(--cg-neutral-0));
  background-color: hsl(var(--cs-alt-purple-1));
}

[data-theme="light"] ::selection {
  color: hsl(var(--cg-neutral-7));
  background-color: hsl(var(--cs-alt-purple-0));
}

body {
  color: hsl(var(--cg-neutral-0));
  background: hsl(var(--c-background));
  width: 100%;
  margin: 0;
  font-family: sans-serif;
  overflow-y: scroll;
}

main {
  max-width: 1440px;
  margin: 0 auto;
}

a {
  color: hsl(var(--cs-alt-purple-0));
  text-decoration-thickness: 2px;
  -webkit-text-decoration-color: hsl(var(--cg-neutral-3));
  text-decoration-color: hsl(var(--cg-neutral-3));
  border-radius: 2px;
  outline: none;
  font-weight: 500;
}

a:hover {
  text-decoration-color: currentColor;
}

a:focus-visible {
  background: hsl(var(--cs-alt-yellow));
  box-shadow: 0 0 0 4px hsl(var(--cs-alt-yellow));
  text-decoration: none;
}

[data-theme="dark"] a:focus-visible {
  color: hsl(var(--cg-neutral-7));
}

[data-theme="light"] a:focus-visible {
  color: hsl(var(--cg-neutral-0));
}

h1 {
  font: var(--f-heading-1);
  margin: 0;
}

h2 {
  font: var(--f-heading-2);
  margin: 0;
}

h3 {
  font: var(--f-heading-3);
  margin: 0;
}

h4 {
  font: var(--f-heading-4);
  margin: 0;
}

h5 {
  font: var(--f-heading-5);
  margin: 0;
}

h6 {
  font: var(--f-heading-6);
  margin: 0;
}

p {
  font: var(--f-paragraph);
  margin: 8px 0 32px;
}

article {
  font: var(--f-article);
}

ul {
  margin: 8px 0 32px;
}

hr {
  background: hsl(var(--cg-neutral-4));
  border: none;
  height: 2px;
  margin: 8px 0;
}

svg {
  vertical-align: middle;
}

[data-theme="dark"] {
  --c-background: 257, 9%, 15%;
  --cs-red: 350, 60%, 50%;
  --cs-alt-red: 0, 80%, 80%;
  --cs-yellow: 40, 80%, 60%;
  --cs-alt-yellow: 40, 70%, 70%;
  --cs-green: 100, 40%, 45%;
  --cs-alt-green: 80, 60%, 65%;
  --cs-alt-purple-1: 250, 60%, 40%;
  --cs-purple: 250, 60%, 55%;
  --cs-alt-purple-0: 250, 80%, 80%;
  --cg-neutral-7: 252, 10%, 10%;
  --cg-neutral-6: 255, 11%, 15%;
  --cg-neutral-5: 247, 10%, 17%;
  --cg-neutral-4: 252, 10%, 20%;
  --cg-neutral-3: 254, 10%, 25%;
  --cg-neutral-2: 249, 10%, 60%;
  --cg-neutral-1: 252, 10%, 80%;
  --cg-neutral-0: 0, 0%, 100%;
  --shadow-small: 0 1px 2px hsla(var(--cg-neutral-7), 15%), 0 2px 4px hsla(var(--cg-neutral-7), 15%);
  --shadow-large: 0 4px 8px hsla(var(--cg-neutral-7), 15%), 0 8px 16px hsla(var(--cg-neutral-7), 15%);
}

body {
  overflow: hidden;
}

main {
  background: radial-gradient(hsla(var(--cg-neutral-6), 75%), hsla(var(--cg-neutral-7), 25%));
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
}

.content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  padding: 16px;
  display: flex;
}

.assistive-hide {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.skydome-logo {
  opacity: .85;
  width: 100%;
  max-width: 256px;
  margin: 0 48px 48px;
}

.video-background {
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
}

.form {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.form-field {
  flex-direction: column;
  margin: 0 0 16px;
  display: flex;
}

.form-input-container {
  background: hsla(var(--cg-neutral-7), 95%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 16px;
  gap: 16px;
  width: 100%;
  padding: 16px;
  display: flex;
}

.form-input {
  color: hsl(var(--cg-neutral-0));
  background: hsla(var(--cg-neutral-4), 50%);
  border: 2px solid hsla(var(--cg-neutral-3), 95%);
  font: var(--f-functional);
  border-radius: 8px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 24px 8px;
  display: block;
}

.form-input:focus-visible {
  outline-offset: 0px;
  color: hsl(var(--cg-neutral-7));
  background-color: hsl(var(--cg-neutral-0));
  border-color: hsl(var(--cg-neutral-7));
  outline: 3px solid hsl(var(--cs-alt-yellow));
}

.form-button-primary {
  color: hsl(var(--cg-neutral-7));
  background-color: hsl(var(--cg-neutral-1));
  font: var(--f-functional);
  cursor: pointer;
  border: 0;
  border-radius: 8px;
  padding: 14px;
  text-decoration: none;
}

.form-button-primary:focus-visible {
  outline-offset: -4px;
  color: hsl(var(--cg-neutral-7));
  background-color: hsl(var(--cs-alt-yellow));
  outline: 2px solid hsl(var(--cg-neutral-7));
}

.from-message {
  font: var(--f-functional);
  text-align: center;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  margin: 0 0 16px;
  padding: 16px;
  display: block;
}

.form-done {
  background: hsla(var(--cs-alt-green), 75%);
  color: hsl(var(--cg-neutral-7));
  display: none;
}

.form-fail {
  background: hsla(var(--cs-alt-red), 75%);
  color: hsl(var(--cg-neutral-7));
  display: none;
}

.powered-by {
  border-radius: 10px;
  width: 200px;
  padding: 8px;
}

.powered-by-logo {
  opacity: .5;
  width: 100%;
}

.powered-by:focus-visible .powered-by-logo {
  filter: invert();
  opacity: 1;
}

/*# sourceMappingURL=index.263f3827.css.map */
