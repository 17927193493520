/* Resets / Scrollbars / Text Selection */
* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: hsl(var(--cg-neutral-2)) transparent;
}

html {
  min-height: 100%;
  padding: 0 !important; /* Prevents layout shifts at root */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: hsl(var(--cg-neutral-2));
  border-radius: 15px;
  border: 4px solid transparent;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background: hsl(var(--cg-neutral-1));
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:active {
  border: 3px solid transparent;
}
::-webkit-scrollbar-track:active {
  background: hsla(var(--cg-neutral-1), 15%);
}

[data-theme="dark"] ::selection {
  color: hsl(var(--cg-neutral-0));
  background-color: hsl(var(--cs-alt-purple-1));
}
[data-theme="light"] ::selection {
  color: hsl(var(--cg-neutral-7));
  background-color: hsl(var(--cs-alt-purple-0));
}

/* Base Styles */
body {
  font-family: sans-serif;
  color: hsl(var(--cg-neutral-0));
  background: hsl(var(--c-background));
  width: 100%;
  margin: 0;
  overflow-y: scroll; /* Prevents layout shifts */
}
main {
  max-width: 1440px;
  margin: 0 auto;
}

a {
  font-weight: 500;
  border-radius: 2px;
  color: hsl(var(--cs-alt-purple-0));
  text-decoration-thickness: 2px;
  text-decoration-color: hsl(var(--cg-neutral-3));
  outline: none;
}
a:hover {
  text-decoration-color: currentColor;
}
a:focus-visible {
  text-decoration: none;
  background: hsl(var(--cs-alt-yellow));
  box-shadow: 0 0 0 4px hsl(var(--cs-alt-yellow));
}
[data-theme="dark"] a:focus-visible {
  color: hsl(var(--cg-neutral-7));
}
[data-theme="light"] a:focus-visible {
  color: hsl(var(--cg-neutral-0));
}

h1 {
  margin: 0;
  font: var(--f-heading-1);
}
h2 {
  margin: 0;
  font: var(--f-heading-2);
}
h3 {
  margin: 0;
  font: var(--f-heading-3);
}
h4 {
  margin: 0;
  font: var(--f-heading-4);
}
h5 {
  margin: 0;
  font: var(--f-heading-5);
}
h6 {
  margin: 0;
  font: var(--f-heading-6);
}

p {
  margin: 8px 0 32px;
  font: var(--f-paragraph);
}

article {
  font: var(--f-article);
}

ul {
  margin: 8px 0 32px;
}

hr {
  border: none;
  height: 2px;
  margin: 8px 0;
  background: hsl(var(--cg-neutral-4));
}

svg {
  vertical-align: middle;
}
