@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Var"),
    url("./ibm-plex-sans-var.woff2") format("woff2-variations"),
    url("https://23852212.fs1.hubspotusercontent-na1.net/hubfs/23852212/quasar-ui/fonts/ibm-plex-sans-var.woff2")
      format("woff2-variations");
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "IBM Plex Mono";
  src: local("IBM Plex Mono"),
    url("./ibm-plex-mono-regular.woff2") format("woff2"),
    url("https://23852212.fs1.hubspotusercontent-na1.net/hubfs/23852212/quasar-ui/fonts/ibm-plex-mono-regular.woff2")
      format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./poppins-semibold.woff2") format("woff2"),
    url("https://23852212.fs1.hubspotusercontent-na1.net/hubfs/23852212/quasar-ui/fonts/poppins-semibold.woff2")
      format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

:root {
  --fs-smallest: 0.938em;
  --fs-base: 1em;
  --fs-medium: 1.2em;
  --fs-large: 1.44em;
  --fs-xlarge: 1.728em;
  --fs-xxlarge: 2.074em;
  --fs-xxxlarge: 2.488em;
  --fs-largest: 2.986em;
  --ff-heading: "Poppins";
  --ff-article: "IBM Plex Sans";
  --ff-paragraph: "IBM Plex Sans";
  --ff-functional: "IBM Plex Sans";
  --ff-code: "IBM Plex Mono";
  --f-heading-1: 500 var(--fs-largest) / var(--fs-largest) var(--ff-heading);
  --f-heading-2: 500 var(--fs-xxxlarge) / var(--fs-largest) var(--ff-heading);
  --f-heading-3: 500 var(--fs-xxlarge) / var(--fs-xxxlarge) var(--ff-heading);
  --f-heading-4: 500 var(--fs-xlarge) / var(--fs-xxlarge) var(--ff-heading);
  --f-heading-5: 500 var(--fs-large) / var(--fs-xlarge) var(--ff-heading);
  --f-heading-6: 500 var(--fs-medium) / var(--fs-large) var(--ff-heading);
  --f-article: 400 var(--fs-base) / var(--fs-xlarge) var(--ff-article);
  --f-paragraph: 400 var(--fs-smallest) / var(--fs-medium) var(--ff-paragraph);
  --f-functional: 400 var(--fs-base) / var(--fs-medium) var(--ff-functional);
  --f-alt-functional: 400 var(--fs-smallest) / var(--fs-base)
    var(--ff-functional);
  --f-code: 400 var(--fs-smallest) / var(--fs-large) var(--ff-code);
}
